import React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import CreateIcon from "@mui/icons-material/Create";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

import FilterIcon from "@mui/icons-material/Filter";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import Filter4Icon from "@mui/icons-material/Filter4";
import Filter5Icon from "@mui/icons-material/Filter5";
import Filter6Icon from "@mui/icons-material/Filter6";
import Filter7Icon from "@mui/icons-material/Filter7";
import Filter8Icon from "@mui/icons-material/Filter8";
import Filter9Icon from "@mui/icons-material/Filter9";
import Filter9PlusIcon from "@mui/icons-material/Filter9Plus";
import NewProjectAlert from "./NewProjectAlert";
import RenameAlert from "./RenameAlert";
import DeleteAlert from "./DeleteAlert";
import HelpAlert from "./HelpAlert";

import marked from "marked";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getAuth, signOut } from "firebase/auth";

import { Link, useHistory, useParams } from "react-router-dom";

import { customAlphabet } from "nanoid";
import { HelpOutlineOutlined } from "@mui/icons-material";
import { Alert, AlertTitle, Tooltip } from "@mui/material";
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      light: "#6DFB72",
      main: "#4caf50",
      dark: "#2A622D",
      contrastText: "#fff",
    },
  },
});

export default function Dashboard(props) {
  const [open, setOpen] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [showHelpAlert, setShowHelpAlert] = React.useState(false);
  const [showEditor, setShowEditor] = React.useState(true);
  const [showViewer, setShowViewer] = React.useState(true);
  const [showNewProjectAlert, setShowNewProjectAlert] = React.useState(false);
  const [showRenameAlert, setShowRenameAlert] = React.useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);
  const [editorText, setEditorText] = React.useState("");
  const [projectName, setProjectName] = React.useState("Note");
  const [projectIndex, setProjectIndex] = React.useState(-1);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();
  const { id } = useParams();

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var userId = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .get()
          .then((doc) => {
            props.setProjects(doc.data().projects);
            var found = false;
            doc.data().projects.forEach((project, index) => {
              if (project.id === id) {
                found = true;
                setProjectIndex(index);
                setProjectName(project.title);
                setEditorText(project.content);
              }
            });
            if (!found) {
              history.push("/no-found");
            }
          })
          .catch(() => {
            history.push("/no-found");
          });
      } else {
        history.push("/no-found");
      }
    });
  }, [id, showRenameAlert, showNewProjectAlert]);

  const saveToFirebase = (text) => {
    props.projects[projectIndex].content = text;
    var userId = firebase.auth().currentUser.uid;
    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        projects: props.projects,
      })
      .then(() => { setIsError(false) })
      .catch(() => { setIsError(true) });
  };

  function createNewPlayground() {
    var playgroundId = nanoid();

    firebase
      .firestore()
      .collection("playgrounds")
      .doc(playgroundId)
      .set({
        content: "# Playground\n---",
        createdAt: new Date(),
      })
      .then(() => {
        history.push(`/playground/${playgroundId}`);
      })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        history.push("/");
      })
  };

  function getProjectIcon(number) {
    switch (number + 1) {
      case 1:
        return <Filter1Icon />;
      case 2:
        return <Filter2Icon />;
      case 3:
        return <Filter3Icon />;
      case 4:
        return <Filter4Icon />;
      case 5:
        return <Filter5Icon />;
      case 6:
        return <Filter6Icon />;
      case 7:
        return <Filter7Icon />;
      case 8:
        return <Filter8Icon />;
      case 9:
        return <Filter9Icon />;
      default:
        return <Filter9PlusIcon />;
    }
  }

  function getMarkdownText(text) {
    var rawMarkup = marked(text, { sanitize: true });
    return { __html: rawMarkup };
  }

  return (
    <ThemeProvider theme={mdTheme}>
      {isError &&
        <Alert style={{ position: "absolute", top: 80, right: 80 }} variant="filled" severity="error">
          <AlertTitle>Error</AlertTitle>
          Saving failed! Check your internet connection and after that try to make changes to the note.
        </Alert>}
      <NewProjectAlert
        open={showNewProjectAlert}
        setOpen={setShowNewProjectAlert}
        projectsLength={props.projects.length}
        history={history}
      />
      <RenameAlert
        open={showRenameAlert}
        setOpen={setShowRenameAlert}
        id={id}
        projectName={projectName}
        projects={props.projects}
      />
      <DeleteAlert
        open={showDeleteAlert}
        setOpen={setShowDeleteAlert}
        history={history}
        id={id}
        projects={props.projects}
      />
      <HelpAlert
        open={showHelpAlert}
        setOpen={setShowHelpAlert}
      />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {projectName}
            </Typography>
            <IconButton
              color="inherit"
              aria-describedby="popover-account"
              onClick={handleClick}
            >
              <PersonIcon />
            </IconButton>
            <Popover
              id="popover-account"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {!props.user ? (
                <Link to="/auth">
                  <Button variant="text">Login / Register</Button>
                </Link>
              ) : (
                <Button variant="text" onClick={logout}>
                  Logout
                </Button>
              )}
            </Popover>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-start",
              px: [1],
            }}
          >
          </Toolbar>
          <List
            style={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <ListItemButton onClick={() => setShowEditor(!showEditor)}>
              <Tooltip title={showEditor ? "Hide Editor" : "Show Editor"}>
                <ListItemIcon>
                  {showEditor ? <div><VisibilityOffIcon />E</div> : <div><VisibilityIcon />E</div>}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={showEditor ? "Hide Editor" : "Show Editor"}
              />
            </ListItemButton>
            <ListItemButton onClick={() => setShowViewer(!showViewer)}>
              <Tooltip title={showViewer ? "Hide Viewer" : "Show Viewer"}>
                <ListItemIcon>
                  {showViewer ? <div><VisibilityOffIcon />V</div> : <vdiv><VisibilityIcon />V</vdiv>}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={showViewer ? "Hide Viewer" : "Show Viewer"}
              />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => setShowRenameAlert(true)}>
              <Tooltip title="Rename Note">
                <ListItemIcon>
                  <CreateIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Rename Note" />
            </ListItemButton>
            <ListItemButton onClick={() => setShowDeleteAlert(true)}>
              <Tooltip title="Delete Note">
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Delete Note" />
            </ListItemButton>
            {props.projects.length > 0 && <Divider />}
            {props.projects.map((project, index) => (
              <ListItemButton
                key={project.id}
                onClick={() => history.push(`/project/${project.id}`)}
              >
                <Tooltip title={project.title}>
                  <ListItemIcon>{getProjectIcon(index)}</ListItemIcon>
                </Tooltip>
                <ListItemText primary={project.title} />
              </ListItemButton>
            ))}
            <Divider />
            <ListItemButton onClick={() => setShowNewProjectAlert(true)}>
              <Tooltip title="Create Note">
                <ListItemIcon>
                  <AddBoxOutlinedIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Create Note" />
            </ListItemButton>
            <ListItemButton onClick={createNewPlayground}>
              <Tooltip title="Create Playground">
                <ListItemIcon>
                  <FilterIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Create Playground" />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => setShowHelpAlert(true)}>
              <Tooltip title="Help">
                <ListItemIcon>
                  <HelpOutlineOutlined />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={"Help"}
              />
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            flexGrow: 1,
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Toolbar />
          <Grid container columns={16}>
            {showEditor && (
              <Grid item xs={showViewer ? 8 : 16}>
                <textarea
                  value={editorText}
                  onChange={(e) => {
                    setEditorText(e.target.value);
                    saveToFirebase(e.target.value);
                  }}
                />
              </Grid>
            )}
            {showViewer && (
              <Grid item xs={showEditor ? 8 : 16}>
                <div
                  dangerouslySetInnerHTML={getMarkdownText(editorText)}
                  className="viewer"
                  target="_blank"
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider >
  );
}
