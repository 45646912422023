import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default function RenameAlert({ open, setOpen, id, projectName, projects }) {
  const [name, setName] = React.useState("");
  const [buttonEnabled, setButtonEnabled] = React.useState(true);

  React.useEffect(() => {
    setButtonEnabled(true);
    setName(projectName);
  }, [open]);

  const handleRename = () => {
    setButtonEnabled(false);
    projects.forEach((project, index) => {
      if (project.id === id) {
        projects[index].title = name;
      }
    });

    var userId = firebase.auth().currentUser.uid;
    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        projects: projects,
      })
      .then(() => { });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Rename Note</DialogTitle>
      <DialogContent>
        <TextField
          id="standard-basic"
          label="New name"
          variant="standard"
          inputProps={{ maxLength: 40 }}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleRename}
          disabled={!buttonEnabled || name.length < 1}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
