import React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";

const mdTheme = createTheme({
  palette: {
    primary: {
      light: "#6DFB72",
      main: "#4caf50",
      dark: "#2A622D",
      contrastText: "#fff",
    },
  },
});

export default function NoMatch() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <AppBar position="absolute">
        <Toolbar>
          <img src="https://i.imgur.com/JBeVlCa.png" alt="Logo" />
        </Toolbar>
      </AppBar>
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 13,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Page Not Found
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Nothing here :(
            </Typography>
            <Stack
              sx={{ pt: 1 }}
              direction="row"
              spacing={1}
              justifyContent="center"
            >
              <Link to="/">
                <Button variant="contained">back to home</Button>
              </Link>
            </Stack>
          </Container>
        </Box>
      </main>
    </ThemeProvider>
  );
}
