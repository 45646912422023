import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Link } from "react-router-dom";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const theme = createTheme({
  palette: {
    primary: {
      light: "#6DFB72",
      main: "#4caf50",
      dark: "#2A622D",
      contrastText: "#fff",
    },
  },
});

export default function ForgotPassword() {
  const [alertType, setAlertType] = React.useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const auth = getAuth();
    sendPasswordResetEmail(auth, data.get("email"))
      .then(() => {
        setAlertType("success");
      })
      .catch(() => {
        setAlertType("error");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          {alertType === "success" ? (
            <Alert severity="success" sx={{ mt: 1 }}>
              Success! Go check your email.
            </Alert>
          ) : (
            alertType === "error" && (
              <Alert severity="error" sx={{ mt: 1 }}>
                Account doesn't exist with the email.
              </Alert>
            )
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Forgot Password
            </Button>
          </Box>
        </Box>
        <Grid container>
          <Link to="/auth">Back to authenticate?</Link>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
