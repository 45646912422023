import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import Dashboard from "./components/Dashboard";
import Playground from "./components/Playground";
import StartBoard from "./components/StartBoard";
import StartPage from "./components/StartPage";
import Auth from "./components/Auth";
import ForgotPassword from "./components/ForgotPassword";
import NoMatch from "./components/NoMatch";

firebase.initializeApp({
  apiKey: "AIzaSyCrUYI2vfN-8EgDiaKS8I_MQEraVSTK1bE",
  authDomain: "jemedev-moods.firebaseapp.com",
  projectId: "jemedev-moods",
  storageBucket: "jemedev-moods.appspot.com",
  messagingSenderId: "1027244053339",
  appId: "1:1027244053339:web:1ccf7ab5ca2787f72c807e",
  measurementId: "G-TMNERVX0JP",
});

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);
  const [projects, setProjects] = React.useState([]);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        var userId = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .get()
          .then((doc) => {
            setProjects(doc.data().projects);
          })
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          {!user ? (
            <StartPage />
          ) : (
            <StartBoard
              user={user}
              setUser={setUser}
              projects={projects}
              setProjects={setProjects}
            />
          )}
        </Route>
        <Route exact path="/auth">
          {!user ? <Auth user={user} setUser={setUser} /> : <Redirect to="/" />}
        </Route>
        <Route exact path="/forgot-password">
          {!user ? <ForgotPassword /> : <Redirect to="/" />}
        </Route>
        <Route path="/playground/:id">
          <Playground
            user={user}
            setUser={setUser}
            projects={projects}
            setProjects={setProjects}
          />
        </Route>
        <Route path="/project/:id">
          <Dashboard
            user={user}
            setUser={setUser}
            projects={projects}
            setProjects={setProjects}
          />
        </Route>
        <Route exact path="/no-found">
          <NoMatch />
        </Route>
        <Route path="*">
          <Redirect to="/no-found" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
