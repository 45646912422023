import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function HelpAlert({ open, setOpen }) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Markdown Help</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table aria-label="Markdown Help Table">
            <TableHead>
              <TableRow>
                <TableCell>Element</TableCell>
                <TableCell>Markdown Syntax</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Heading</TableCell>
                <TableCell># H1<br />## H2<br />### H3</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Bold</TableCell>
                <TableCell>**text**</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Italic</TableCell>
                <TableCell>*text*</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Strikethrough</TableCell>
                <TableCell>~~text~~</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Blockquote</TableCell>
                <TableCell>{">"} text</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Horizontal line</TableCell>
                <TableCell>---</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ordered List</TableCell>
                <TableCell>1. First<br />2. Second<br />3. Third</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Unordered List</TableCell>
                <TableCell>- First<br />- Second<br />- Third</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Task List</TableCell>
                <TableCell>- [x] Done<br />- [ ] Not done</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Link</TableCell>
                <TableCell>[text](https://moods-notes.netlify.app)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>![text](image-link.jpg)</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
