import React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

import FilterIcon from "@mui/icons-material/Filter";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import Filter4Icon from "@mui/icons-material/Filter4";
import Filter5Icon from "@mui/icons-material/Filter5";
import Filter6Icon from "@mui/icons-material/Filter6";
import Filter7Icon from "@mui/icons-material/Filter7";
import Filter8Icon from "@mui/icons-material/Filter8";
import Filter9Icon from "@mui/icons-material/Filter9";
import Filter9PlusIcon from "@mui/icons-material/Filter9Plus";

import NewProjectAlert from "./NewProjectAlert";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getAuth, signOut } from "firebase/auth";

import { Link, useHistory } from "react-router-dom";

import { customAlphabet } from "nanoid";
import { ListItemButton, Tooltip } from "@mui/material";
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      light: "#6DFB72",
      main: "#4caf50",
      dark: "#2A622D",
      contrastText: "#fff",
    },
  },
});

export default function StartBoard(props) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showNewProjectAlert, setShowNewProjectAlert] = React.useState(false);

  const history = useHistory();

  React.useEffect(() => {
    var userId = firebase.auth().currentUser.uid;
    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get()
      .then((doc) => {
        props.setProjects(doc.data().projects);
      })
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        history.push("/");
      })
  };

  function getProjectIcon(number) {
    switch (number + 1) {
      case 1:
        return <Filter1Icon />;
      case 2:
        return <Filter2Icon />;
      case 3:
        return <Filter3Icon />;
      case 4:
        return <Filter4Icon />;
      case 5:
        return <Filter5Icon />;
      case 6:
        return <Filter6Icon />;
      case 7:
        return <Filter7Icon />;
      case 8:
        return <Filter8Icon />;
      case 9:
        return <Filter9Icon />;
      default:
        return <Filter9PlusIcon />;
    }
  }

  function createNewPlayground() {
    var playgroundId = nanoid();

    firebase
      .firestore()
      .collection("playgrounds")
      .doc(playgroundId)
      .set({
        content: "# Playground\n---",
        createdAt: new Date(),
      })
      .then(() => {
        history.push(`/playground/${playgroundId}`);
      })
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <NewProjectAlert
        open={showNewProjectAlert}
        setOpen={setShowNewProjectAlert}
        projectsLength={props.projects.length}
        history={history}
      />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Moods
            </Typography>
            <IconButton
              color="inherit"
              aria-describedby="popover-account"
              onClick={handleClick}
            >
              <PersonIcon />
            </IconButton>
            <Popover
              id="popover-account"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {!props.user ? (
                <Link to="/auth">
                  <Button variant="text">Login / Register</Button>
                </Link>
              ) : (
                <Button variant="text" onClick={logout}>
                  Logout
                </Button>
              )}
            </Popover>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-start",
              px: [1],
            }}
          >
          </Toolbar>
          <List
            style={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {props.projects.map((project, index) => (
              <ListItemButton
                key={project.id}
                onClick={() => history.push(`/project/${project.id}`)}
              >
                <Tooltip title={project.title}>
                  <ListItemIcon>{getProjectIcon(index)}</ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={{ color: "#000" }}
                  primary={project.title}
                />
              </ListItemButton>
            ))}
            <Divider />
            <ListItemButton onClick={() => setShowNewProjectAlert(true)}>
              <Tooltip title="Create Note">
                <ListItemIcon>
                  <AddBoxOutlinedIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Create Note" />
            </ListItemButton>
            <ListItemButton onClick={createNewPlayground}>
              <Tooltip title="Create Playground">
                <ListItemIcon>
                  <FilterIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Create Playground" />
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Toolbar />
          <Grid sx={{ mt: 4 }}>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
              sx={{ p: 3 }}
            >
              Select or create a new note from the left menu. In
              playground, you can edit and share your note by url, but they
              do not save in your personal notes list.
            </Typography>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
