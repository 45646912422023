import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default function DeleteAlert({ open, setOpen, history, id, projects }) {
  const [buttonEnabled, setButtonEnabled] = React.useState(true);

  React.useEffect(() => {
    setButtonEnabled(true);
  }, [open]);

  const handleDelete = () => {
    setButtonEnabled(false);
    var userId = firebase.auth().currentUser.uid;
    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        projects: projects.filter((project) => project.id !== id),
      })
      .then(() => { });
    setOpen(false);
    history.push("/");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Delete Note</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this note?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={handleDelete} disabled={!buttonEnabled}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
