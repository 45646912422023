import React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Link, useHistory } from "react-router-dom";

import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);

const mdTheme = createTheme({
  palette: {
    primary: {
      light: "#6DFB72",
      main: "#4caf50",
      dark: "#2A622D",
      contrastText: "#fff",
    },
  },
});

export default function StartPage() {
  const history = useHistory();

  function createNewPlayground() {
    var playgroundId = nanoid();

    firebase
      .firestore()
      .collection("playgrounds")
      .doc(playgroundId)
      .set({
        content: "# Playground\n---",
        createdAt: new Date(),
      })
      .then(() => {
        history.push(`/playground/${playgroundId}`);
      })
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <AppBar position="absolute">
        <Toolbar>
          <img src="https://i.imgur.com/JBeVlCa.png" alt="Logo" />
        </Toolbar>
      </AppBar>
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 13,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Moods
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Ultimate markdown editor and viewer. Edit content while seeing live preview of the result.
              Try our playground or register an account.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Link to="/auth">
                <Button variant="contained">Login / Register</Button>
              </Link>
              <Button variant="outlined" onClick={createNewPlayground}>
                Create Playground
              </Button>
            </Stack>
          </Container>
        </Box>
      </main>
    </ThemeProvider>
  );
}
