import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);

export default function NewProjectAlert({ open, setOpen, projectsLength, history }) {
  const [name, setName] = React.useState('Project 1');
  const [buttonEnabled, setButtonEnabled] = React.useState(true);

  React.useEffect(() => {
    setName(`Project ${projectsLength + 1}`);
    setButtonEnabled(true);
  }, [projectsLength]);

  const handleCreate = () => {
    setButtonEnabled(false);
    var userId = firebase.auth().currentUser.uid;
    var projectId = nanoid();
    const project = {
      title: name,
      content: "# " + name + "\n---",
      id: projectId,
      createdAt: new Date(),
    };

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set(
        { projects: firebase.firestore.FieldValue.arrayUnion(project) },
        { merge: true }
      )
      .then(() => {
        history.push(`/project/${projectId}`);
        setOpen(false);
      })
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Create Note</DialogTitle>
      <DialogContent>
        <TextField
          id="standard-basic"
          label="Note name"
          variant="standard"
          inputProps={{ maxLength: 40 }}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCreate} disabled={!buttonEnabled}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
